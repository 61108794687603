<template>
  <InspectionModal
    :endpoint="endpoint + '/?short=1'"
    :loadDetail="loadDetail"
    :seed="selectedRma">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ action === null ? 'Inspect' : capitalize(action)}} Return</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && !loadingReturns && props.record !== null" class="">
              <!-- Unconfirmed View -->
              <div v-if="!confirm">
                <p class="has-text-weight-bold mb-sm">Return Details</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{{ props.record.id }}</td>
                    </tr>
                    <tr>
                      <th>RMA Number</th>
                      <td>
                        <p v-if="props.record.rma_number">{{ props.record.rma_number }}</p>
                        <p v-else class="has-text-grey-light is-italic">None Provided</p>
                      </td>
                    </tr>
                    <tr>
                      <th>PO #</th>
                      <td>
                        <router-link
                          class="has-text-weight-bold has-text-info"
                          :to="{ name: 'order.summary', params: { id: props.record.order_id} }"
                        >{{ props.record.purchase_order_number }}</router-link>
                      </td>
                    </tr>
                    <tr>
                      <th>Total RMA Value</th>
                      <td class="has-text-danger has-text-weight-bold">
                        <CurrencyLocale :value="parseFloat(props.record.locale_total_amount)" :currency="props.record.locale_currency"/>
                      </td>
                    </tr>
                    <tr>
                      <th>Reason</th>
                      <td>{{ props.record.reason }}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td><Status :rma="props.record" /></td>
                    </tr>
                    <tr>
                      <th>Return Tracking Number</th>
                      <td>{{ trackingNumbers }}</td>
                    </tr>
                    <tr v-if="carrier !== null">
                      <th>Return Carrier</th>
                      <td>{{ carrier.name }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- RMA Lines -->
                <div class="mb-lg">
                  <p class="has-text-weight-bold mb-sm">Return Line Items</p>
                  <table class="table is-fullwidth is-vcentered is-striped is-size-7 is-narrow border">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th class="has-text-centered" style="width:128px">Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="line in props.record.rma_lines" :key="line.id">
                        <td>
                          <Identifiers :variant="line.variant" nameInteraction="none" :showImage="false" />
                        </td>
                        <td class="has-text-centered">{{ line.quantity }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- RMA Message -->
                <div class="mb-lg">
                  <p class="has-text-weight-bold mb-sm">Return Messages</p>
                  <div v-for="memo in props.record.memos" :key="memo.id">
                    <Memo :memo="memo" />
                  </div>
                </div>
              </div>
              <!-- Get Confirmation -->
              <div v-if="confirm && action === 'approve'">
                <div class="has-background-light pa-sm pt-md mb-sm rounded-lg">
                  <b-checkbox v-model="createCreditMemo">
                    Create a credit memo on approval?
                  </b-checkbox>
                </div>
                <div v-if="createCreditMemo" class="mb-sm">
                  <b-field horizontal label="Credit Number" custom-class="is-small">
                    <b-input v-model="creditNumber" size="is-small" type="text" placeholder="Credit Number"></b-input>
                  </b-field>
                  <p class="has-text-weight-bold mb-sm">Return Line Items</p>
                  <table class="table is-fullwidth is-vcentered is-striped is-size-7 is-narrow border">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th class="has-text-centered" style="width:128px">Qty Returned</th>
                        <th class="has-text-centered" style="width:128px">Qty Credited</th>
                        <th class="has-text-centered" style="width:128px">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="line in props.record.rma_lines" :key="line.id">
                        <td>
                          <Identifiers :variant="line.variant" nameInteraction="none" :showImage="false" />
                        </td>
                        <td class="has-text-centered">{{ line.quantity }}</td>
                        <td class="has-text-centered">{{ line.quantity }}</td>
                        <td class="has-text-centered"><CurrencyLocale :value="parseFloat(line.net_price || 0)" :currency="props.record.locale_currency"/></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Subtotal</th>
                        <th class="has-text-centered" style="width:128px">{{ itemsCount }}</th>
                        <th class="has-text-centered" style="width:128px">{{ itemsCount }}</th>
                        <th class="has-text-centered" style="width:128px"><CurrencyLocale :value="parseFloat(props.record.locale_total_amount)" :currency="props.record.locale_currency"/></th>
                      </tr>
                    </tfoot>
                  </table>
                  <div v-if="adjustments.length > 0">
                    <p class="has-text-weight-bold mb-sm">Adjustments</p>
                    <table class="table is-fullwidth is-vcentered is-striped is-size-7 is-narrow border mb-sm">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th class="has-text-centered" style="width:256px">Adjustment</th>
                          <th class="has-text-centered" style="width:96px">Amount</th>
                          <th class="has-text-centered" style="width:32px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(adjustment, index) in adjustments" :key="index">
                          <td>
                            <b-field>
                              <b-input v-model="adjustment.description" size="is-small"></b-input>
                            </b-field>
                          </td>
                          <td>
                            <div class="columns">
                              <div class="column has-text-centered">
                                <b-field>
                                  <b-select v-model="adjustment.type" size="is-small" expanded>
                                    <option value="%">%</option>
                                    <option value="flat">flat</option>
                                  </b-select>
                                </b-field>
                              </div>
                              <div class="column has-text-right">
                                <b-field>
                                  <b-input v-model="adjustment.value" type="number" step="any" size="is-small"></b-input>
                                </b-field>
                              </div>
                            </div>
                          </td>
                          <td class="has-text-right" style="vertical-align:middle">
                            <span v-if="adjustment.type === '%'"><CurrencyLocale :value="parseFloat(props.record.locale_total_amount * (adjustment.value / 100))" :currency="props.record.locale_currency"/></span>
                            <span v-else><CurrencyLocale :value="parseFloat(adjustment.value)" :currency="props.record.locale_currency"/></span>
                          </td>
                          <td>
                            <b-button rounded outlined type="is-danger is-small" @click="removeAdjustment(index)">&#10006;</b-button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <th>Total Adjustments</th>
                        <th></th>
                        <th class="has-text-right"><CurrencyLocale :value="parseFloat(adjustmentsTotal)" :currency="props.record.locale_currency"/></th>
                        <th></th>
                      </tfoot>
                    </table>
                  </div>
                  <div class="columns">
                    <div class="column has-text-right">
                      <b-button type="is-small is-text" @click="addAdjustment">+ Add an Adjustment</b-button>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-two-thirds is-offset-one-third">
                      <table class="table is-fullwidth is-vcentered is-narrow is-bordered mb-sm">
                        <tbody>
                          <tr>
                            <td rowspan="3" class="pa-sm" style="padding:5px;transform:rotate(270deg);text-align:center;vertical-align:middle;writing-mode:lr-tb;">SUMMARY</td>
                            <td>Subtotal</td>
                            <td class="has-text-right"><strong><CurrencyLocale :value="parseFloat(props.record.locale_total_amount)" :currency="props.record.locale_currency"/></strong></td>
                          </tr>
                          <tr>
                            <td>Adjustments</td>
                            <td class="has-text-right"><strong><CurrencyLocale :value="parseFloat(adjustmentsTotal)" :currency="props.record.locale_currency"/></strong></td>
                          </tr>
                          <tr>
                            <td>Total Credit Amount</td>
                            <td class="has-text-right"><strong><CurrencyLocale :value="parseFloat(totalCreditAmount(props.record.locale_total_amount))" :currency="props.record.locale_currency"/></strong></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="confirm" class="colums pa-sm">
                <div class="column is-full has-text-centered pb-sm">
                  Are you sure?
                </div>
                <div class="column is-full">
                  <div class="buttons is-centered">
                    <b-button @click="clearConfirm">Cancel</b-button>
                    <b-button class="is-primary" @click="save">Confirm</b-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading item..." />
            </div>
          </section>
          <footer class="modal-card-foot space-between">
            <b-button @click="$parent.close()">Close</b-button>
            <div class="buttons" v-if="context.isSupplier() && !confirm">
              <b-button type="is-danger" @click="confirmRejection" :disabled="confirm || context.isMerchant()">Reject Return</b-button>
              <b-button type="is-success" @click="confirmApproval" :disabled="confirm || context.isMerchant()">Approve Return</b-button>
            </div>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import { mapGetters } from 'vuex'
import InspectionModal from '../InspectionModal'
import Identifiers from '@/components/Models/Variant/Identifiers'
import Status from '@/components/Presenters/Rma/Status'
import Memo from '@/components/Models/Memo/Memo'

export default {
  components: {
    Identifiers,
    InspectionModal,
    Memo,
    Status
  },
  data () {
    return {
      loadDetail: false,
      confirm: false,
      createCreditMemo: true,
      adjustments: [],
      action: null,
      creditNumber: '',
      loadingReturns: true,
      returnDetails: null,
      carrier: null,
      trackingNumbers: 'N/A'
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedRma !== null) {
        return this.context.route + '/rmas/' + this.selectedRma.id
      }
      return null
    },
    itemsCount () {
      if (this.selectedRma !== null) {
        return this.selectedRma.rma_lines.reduce((a, b) => a + (b.quantity || 0), 0)
      }
      return 0
    },
    adjustmentsTotal () {
      const sum = 0
      if (this.adjustments !== null) {
        return this.adjustments.reduce((a, b) => a + (b.type === '%' ? this.selectedRma.locale_total_amount * ((parseFloat(b.value) || 0) / 100) : (parseFloat(b.value) || 0)), 0)
      }
      return sum
    },
    json () {
      if (this.action === 'approve' && this.createCreditMemo) {
        return {
          credit: {
            order: {
              id: this.selectedRma.order_id
            },
            rma: {
              id: this.selectedRma.id
            },
            credit_number: this.creditNumber,
            credit_lines: this.selectedRma.rma_lines.map(line => ({
              variant: {
                id: line.variant.id
              },
              quantity: line.quantity
            })),
            adjustments: this.adjustments.filter(adj => !isNaN(adj.value) && parseFloat(adj.value) !== 0).map(adj => ({
              description: adj.description,
              net_price: this.calcAdjustmentAmount(adj.type, adj.value).toFixed(2)
            }))
          }
        }
      }
      return null
    },
    eventName () {
      if (this.action === null) {
        return this.action
      }
      return this.action === 'approve' ? 'rma:approved' : 'rma:rejected'
    }
  },
  methods: {
    removeAdjustment (index) {
      this.adjustments.splice(index, 1)
    },
    addAdjustment () {
      this.adjustments.push({
        description: '',
        type: '%',
        value: 0,
        extendedValue: 0
      })
    },
    totalCreditAmount (totalAmount) {
      return (totalAmount - this.adjustmentsTotal)
    },
    confirmApproval () {
      this.action = 'approve'
      this.confirm = true
    },
    confirmRejection () {
      this.action = 'reject'
      this.confirm = true
    },
    clearConfirm () {
      this.action = null
      this.confirm = false
    },
    calcAdjustmentAmount (type, value) {
      return type === '%' ? this.selectedRma.locale_total_amount * ((parseFloat(value) || 0) / 100) : (parseFloat(value) || 0)
    },
    capitalize (word) {
      return word[0].toUpperCase() + word.slice(1)
    },
    loadReturns () {
      this.loadingReturns = true
      this.$http.get(this.endpoint + '/returns/').then(response => {
        this.returnDetails = response.data.results
        if (this.returnDetails) {
          this.carrier = ('carrier' in response.data.results[0]) ? response.data.results[0].carrier : null
        }
        const trackingNumbersList = response.data.results.map(el => el.return_tracking_number)
        if (trackingNumbersList.length > 0) {
          this.trackingNumbers = trackingNumbersList.join(',')
        }
      }).finally(() => {
        this.loadingReturns = false
      })
    },
    save () {
      this.saving = true
      this.$http.put(`${this.endpoint}/${this.action}/`, this.json).then(() => {
        this.$parent.$emit(this.eventName)
        this.$buefy.toast.open({ type: 'is-success', message: this.action === 'approve' ? 'RMA Approved.' : 'RMA Rejected.' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: `Your attempt to ${this.action} this order failed. Please refresh and try again.` })
      }).finally(() => {
        this.saving = false
        this.$parent.close()
      })
    }
  },
  mounted () {
    if (this.selectedRma !== null) {
      this.loadReturns()
    }
  },
  props: ['selectedRma']
}
</script>

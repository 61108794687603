<template>
  <div>
    <PerformanceSummary
      :orders_count="summary['orders_count']"
      :estimated_gmv="summary['estimated_gmv']"
      :estimated_aov="summary['estimated_aov']"
      :connections="partnerCount"
      :loading="loading"
    ></PerformanceSummary>
    <div class="has-background-white pa-md rounded-lg mb-md has-text-centered">
      <div v-if="!isChartDataReady">
        <loading />
      </div>
      <div class="container">
        <LineChartComponent
          v-if="dimension === 'date'"
          :chartData="chartData"
          :chartOptions="chartOptions"
          :styles="chartStyles"/>
        <div v-else>
          <BarChartComponent
            :chartData="chartData"
            :chartOptions="chartOptions"
            :styles="chartStyles" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChartComponent from '../../../components/Graphs/LineChartComponent.vue'
import BarChartComponent from '../../../components/Graphs/BarChartComponent.vue'
import PerformanceSummary from '@/modules/Performance/views/PerformanceSummary.vue'

export default {
  components: {
    LineChartComponent,
    BarChartComponent,
    PerformanceSummary
  },
  data () {
    return {
      canCancel: false,
      isFullPage: false,
      metricsDisplay: {
        orders_count: '# Sales',
        estimated_gmv: 'GMV ($)',
        estimated_aov: 'AVG Sale Value ($)',
        wholesale_cost: 'Wholesale Cost ($)',
        connections: '# Brands'
      },
      isChartDataReady: false
    }
  },
  computed: {
    chartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scaleShowValues: true,
        scales: {
          x: {
            ticks: {
              autoSkip:
                (this.dimension === 'date' ||
                  this.chartData.datasets[0].data.length > 30)
            }
          },
          y: {
            ticks: {
              beginAtZero: true,
              callback: (value, index, values) => {
                if (
                  this.metric === 'connections' || this.metric === 'orders_count') {
                  return this.$numbers(value).format({
                    thousandSeparated: true,
                    mantissa: 0
                  })
                } else {
                  return this.$numbers(value).formatCurrency({
                    thousandSeparated: true,
                    mantissa: 2
                  })
                }
              }
            }
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label
              let value = ''
              if (
                this.metric === 'connections' ||
                this.metric === 'orders_count'
              ) {
                value = this.$numbers(tooltipItem.yLabel).format({
                  thousandSeparated: true,
                  mantissa: 0
                })
              } else {
                value = `${this.$numbers(tooltipItem.yLabel).formatCurrency({
                  thousandSeparated: true,
                  mantissa: 2
                })}`
              }
              return `${label}: ${value}`
            }
          }
        }
      }
    },
    chartStyles: function () {
      return {
        position: 'relative',
        height: '400px'
      }
    },
    chartData: function () {
      const unordered = this.responseData[this.dimension] || {}
      let results = []
      const data = {
        datasets: [
          {
            label: this.metricsDisplay[this.metric],
            backgroundColor: '#52a8f6',
            fill: true,
            tension: 0.4,
            data: []
          }
        ],
        labels: []
      }
      switch (this.dimension) {
        case 'date':
          Object.keys(unordered).sort().forEach((key) => {
            unordered[key].label = key.substring(0, 10)
            data.labels.push(this.$dates(unordered[key].label).format('MMM D, YYYY'))
            results.push(unordered[key])
            data.datasets[0].data.push(unordered[key][this.metric])
          })
          break
        case 'order_status':
          Object.keys(unordered).sort().forEach((key) => {
            unordered[key].label = key
            data.labels.push(key)
            results.push(unordered[key])
            data.datasets[0].data.push(unordered[key][this.metric])
          })
          break
        case 'brand':
          results = this.$utils.orderBy(unordered, 'wholesale_cost', 'desc')
          results.forEach((item) => {
            item.label = item.code
            data.datasets[0].data.push(item[this.metric])
            data.labels.push(item.label)
          })
          break
        default:
          results = this.$utils.orderBy(unordered, 'estimated_gmv', 'desc')
          results.forEach((item) => {
            item.label =
              this.dimension === 'variant' ? item.identifier : item.code
            data.datasets[0].data.push(item[this.metric])
            data.labels.push(item.label)
          })
          break
      }
      return data
    },
    summary: function () {
      const summary = {}
      let attrs = Object.keys(this.metricsDisplay)
      attrs.forEach(attr => {
        summary[attr] = 0
      })
      if (this.responseData[this.dimension] !== undefined) {
        let maxConnections = 0
        attrs = (attrs.filter(item => item !== 'connections' && item !== 'estimated_aov'))
        const keys = Object.keys(this.responseData[this.dimension])
        keys.forEach((key) => {
          const value = this.responseData[this.dimension][key]
          attrs.forEach((attribute) => {
            summary[attribute] += value[attribute]
          })
          if (value.connections > maxConnections) {
            maxConnections = value.connections
          }
        })
        summary.connections = maxConnections
        summary.estimated_aov = summary.orders_count > 0 ? summary.estimated_gmv / summary.orders_count : 0
      }
      return summary
    }
  },
  props: {
    responseData: {
      type: Object
    },
    metric: {
      type: String
    },
    dimension: {
      type: String,
      default: 'date'
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 300
    },
    partnerCount: {
      type: Number,
      default: 0
    }
  },
  mounted () {},
  watch: {
    chartData: {
      handler () {
        if (this.chartData && this.chartData.labels.length > 0) {
          this.isChartDataReady = true
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<template>
  <div>
    <div>
      <div class="mb-sm">
        <p class="has-text-primary has-text-weight-bold">Select Template</p>
      </div>
      <div class="columns is-vcentered">
        <div class="column">
          <b-select
            v-model="template"
            placeholder="Select a template..."
            :disabled="loading || saving"
            :loading="loading"
            expanded>
            <option v-for="template in templates" :value="template" :key="template.id">{{ template.name }}</option>
          </b-select>
        </div>
        <div class="column is-narrow">
          <a v-if="template" href="#" @click.prevent="downloadSample" class="has-text-info">Download Empty Template</a>
          <span v-else class="has-text-grey-light">Download Empty Template</span>
        </div>
      </div>
    </div>
    <!-- Mappings -->
    <div v-if="template !== null" class="mt-lg">
      <!-- Counter -->
      <div v-if="!loadingMappings && mappings.length > 0" class="mb-md">
        <div class="columns is-vcentered">
          <div class="column">
            <p class="has-text-weight-bold has-text-black">Column Headers ({{ mappings.length }})</p>
            <p class="has-text-grey-light">
              <span v-if="showMappings">Your file should contain the following column headers</span>
              <span v-else>Click 'show' to display the expected file headers.</span>
            </p>
          </div>
          <div class="column is-narrow ">
            <a href="#" class="has-text-info" @click.prevent="toggleMappings" v-text="(showMappings) ? 'Hide Headers' : 'Show Headers'"></a>
          </div>
        </div>
      </div>
      <div v-if="!loadingMappings && mappings.length > 0 && showMappings">
        <div v-for="(mapping, index) in mappings" :key="mapping.id" >
          <div class="has-background-light rounded-lg px-md py-sm is-size-7" :class="(index !== 0) ? 'mt-xxs' : ''">
            <div class="columns is-vcentered">
              <div class="column">
                <p class="has-text-weight-bold">
                  {{ mapping.title }}
                  <span v-if="mapping.is_required" class="has-text-danger has-text-weight-bold">*</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- File Upload -->
    <div v-if="template !== null" class="mt-lg">
      <div class="mb-lg">
        <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile" :showLabel='true'/>
      </div>
      <!-- Email Me -->
      <!-- <div class="mb-lg">
        <b-field>
          <b-checkbox v-model="emailOnceComplete" true-value="1" false-value="0">Email me once my feed is complete.</b-checkbox>
        </b-field>
      </div> -->
    </div>
    <div class="mt-md" v-if="!loadingMappings && mappings.length > 0">
      <a v-if="!toggleAdvancedOptions" href="#" class="has-text-info has-text-weight-bold" @click.prevent="toggleAdvancedOptions = true" >Show Advanced Options</a>
      <div v-if="toggleAdvancedOptions">
        <div v-if="context.isSupplier() && showAutoSkuUpdateToggle" class="mb-md">
          <b-field label="SKU Update behavior">
            <b-checkbox v-model="setAutoSkuUpdate">Allow SKU updates based on UPC matches.</b-checkbox>
          </b-field>
          <b-message v-if='setAutoSkuUpdate' type="is-warning">Warning! Enabling this option may cause your SKUs to update (if a matching UPC is found) which can create issues for your connected merchant partners. Please proceed with caution.</b-message>
        </div>
        <b-field
          label="Transformer"
          expanded>
          <TransformerSelect v-model="transformer" />
        </b-field>
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="queueFile"
          >Begin Import</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import CoreUpload from '@/components/CoreUpload'
import TransformerSelect from '@/components/Models/Transformers/TransformerSelect'

export default {
  components: {
    CoreUpload,
    TransformerSelect
  },
  computed: {
    ...mapGetters(['api', 'app', 'context']),
    loading () {
      return (this.loadingTemplates)
    },
    ready () {
      return (!this.loadingTemplates && !this.saving && this.template !== null && this.tempfile !== null)
    },
    json () {
      if (!this.template || !this.tempfile) return null
      const json = {
        template: {
          id: this.template.id
        },
        direction: 'import',
        data_type: 'products',
        source: 'console',
        behavior: 'merge',
        info: {
          tempfile: this.tempfile.id
        },
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
      if (this.transformer && this.transformer !== null) {
        json.info.transformer_ids = [this.transformer.id]
      }
      if (this.showAutoSkuUpdateToggle) {
        json.info.allow_identifier_updates = this.setAutoSkuUpdate ? 1 : 0
      }
      return json
    }
  },
  data () {
    return {
      loadingTemplates: true,
      loadingMappings: false,
      templates: [],
      mappings: [],
      showMappings: false,
      template: null,
      tempfile: null,
      emailOnceComplete: 1,
      saving: false,
      errors: {},
      feed: null,
      transformer: null,
      toggleAdvancedOptions: false,
      showAutoSkuUpdateToggle: false,
      setAutoSkuUpdate: false
    }
  },
  methods: {
    loadTemplates () {
      this.loadingTemplates = true
      const endpoint = this.context.route + '/templates/'
      const query = {
        params: {
          direction: 'import',
          data_type: 'products',
          limit: 200
        }
      }
      this.$http.get(endpoint, query).then(response => {
        const templates = []
        response.data.results.forEach(template => {
          // custom templates gets added...
          if (template.brand || template.retailer) {
            templates.push(template)
          // platform templates get added based on platform (for now)
          } else if (template.brand === null && template.retailer === null) {
            // if platform is fabric, only allow "official" templates...
            if (this.app.platformCode === 'fabric' && template.name.includes('fabric')) {
              templates.push(template)
            // if platform is revcascade, ignore "official" templates..
            } else if (this.app.platformCode === 'revcascade' && !template.name.includes('fabric')) {
              templates.push(template)
            }
          }
        })
        // sort the templates by name
        this.templates = this.$utils.orderBy(templates, ['name'], ['asc'])
        // if only 1, select it...
        if (this.templates.length === 1) {
          this.template = this.templates[0]
        }
        this.loadingTemplates = false
      })
    },
    loadMappings () {
      this.toggleAdvancedOptions = false
      this.loadingMappings = true
      const endpoint = this.context.route + '/templates/' + this.template.id + '/mappings/'
      const body = {
        params: {
          pagination: 0
        }
      }
      this.$http.get(endpoint, body).then(response => {
        this.mappings = response.data.results
        const totalCount = response.data.count
        if (totalCount > 0) {
          this.showMappings = totalCount < 10
          const hasUPCMapping = response.data.results.some(mapping => mapping.attribute.code === 'upc')
          this.showAutoSkuUpdateToggle = hasUPCMapping
        }
        this.loadingMappings = false
      })
    },
    queueFile () {
      this.saving = true
      const endpoint = this.context.route + '/feed-queue/'
      this.$http.post(endpoint + 'enqueue/', this.json).then(response => {
        this.$http.put(endpoint + response.data.id + '/run/').then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Import scheduled!' })
          this.$emit('feed:queued', response.data)
          this.$emit('navigate', 'Progress')
          this.$router.replace({ name: 'catalog.import.products', query: { feed_id: response.data.id } })
          this.saving = false
        })
      })
    },
    downloadSample () {
      const link = document.createElement('a')
      link.target = '_blank'
      // Download sample location
      if (this.template.sample_location) {
        link.href = this.template.sample_location
        document.body.appendChild(link)
        link.click()
      } else {
        // Download template mapping, if sample location is not provided
        this.downloadTemplateMapping(link)
      }
    },
    downloadTemplateMapping (link) {
      const endpoint = this.api.baseRoute + '/' + this.context.route + '/templates/' + this.template.id + '/mappings/?pagination=0&format=xlsx'
      axios({
        url: endpoint,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        link.href = url
        link.setAttribute('download', this.template.name + '.xlsx')
        document.body.appendChild(link)
        link.click()
        setTimeout(() => window.URL.revokeObjectURL(url), 0)
      })
    },
    goBack () {
      this.$router.push({ name: 'catalog' })
    },
    toggleMappings () {
      this.showMappings = !this.showMappings
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  },
  mounted () {
    this.loadTemplates()
  },
  watch: {
    template (template) {
      if (template) {
        this.loadMappings()
      }
    }
  }
}
</script>
